module controllers {
    export module tariff {
        
        export class prohibitedAndRestrictedCtrl {

            static $inject = [
                '$scope',
                '$rootScope',
                '$q',
                '$uibModal',
                'generalService',
                'bsLoadingOverlayService',
                'countryService',
                '$timeout',
                'productTariffService',
                '$anchorScroll',
                '$state',
                'prohibitedAndRestrictedService',
                '$transitions'
            ];
           
            myHook: any;

            proResSearch: interfaces.tariff.IProhibitedAndRestrictedSearch;

            constructor(
                private $scope: ng.IScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private generalService: interfaces.applicationcore.IGeneralService,
                private bsLoadingOverlayService,
                private countryService: interfaces.applicationcore.ICountryService,
                public $timeout: ng.ITimeoutService,
                public productTariffService: services.tariff.productTariffService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                private prohibitedAndRestrictedService: interfaces.tariff.IProhibitedAndRestrictedService,
                private $transitions: ng.ui.core.ITransition,                
            ) {
                this.myHook = $transitions.onSuccess({
                    to: 'auth.ProhibitedAndRestricted',
                    from: 'auth.ProhibitedAndRestricted.**'
                }, () => {
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }
        };

        angular.module("app").controller("prohibitedAndRestrictedCtrl", controllers.tariff.prohibitedAndRestrictedCtrl);
    }
}